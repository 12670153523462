import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <h1>About</h1>
        <p>
          Hey there! Thanks for checking me out! My name is Miguel, I'm a
          developer who've been living around the world and I've been on my
          minimalism journey for a few good years now.
        </p>
        <p>
          I made this blog both as self development project and mostly of course
          share the knwoledge I gained throught trial and error and real world
          experience when it comes to <bold>minimalism</bold>, and minimalist{" "}
          <bold>fashion</bold>.
        </p>

        <h6>
          For any questions/enquiries/feedback feel free to contact me at&nbsp;
          <a href="mailto: minimalist.edge.contactus@gmail.com">
            minimalist.edge.contactus@gmail.com
          </a>
        </h6>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
